.quiz-grid
  text-align: center
  cursor: pointer
  width: 100%
.image-block
  text-align: center
  .image-item
    max-height: 400px
    max-width: 500px
.quiz-red
  background-color: #ff9494
.quiz-green
  background-color: #a4ffa4
.text-center
  text-align: center
.text-16
  font-size: 16px
  margin-bottom: 5px
.green
  color: green
.red
  color: yellow
.red
  color: red
.mt-5
  margin-top: 5px

.question-block
  margin: 10px 0
  .ant-card-grid
    width: 100%
    max-width: 500px
.quiz-variants-space
  width: 100%
  text-align: center
  margin-top: 15px
  margin-bottom: 15px
  .ant-space-item
    display: flex
    justify-content: center
    .ant-card-grid
      padding: 5px
      cursor: pointer
      &.selected-true
        background-color: green
        color: #FFFFFF
      &.selected-false
        background-color: red
        color: #FFFFFF
      &.selected,&.selected-false,&.selected-true
        cursor: default